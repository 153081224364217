import { useEffect, useState } from "react";
import PortfolioList from "../portfolioList/portfolioList"
import "./portfolio.scss"
import {
  featuredPortfolio,
  webPortfolio,
  mobilePortfolio,
  designPortfolio,
  contentPortfolio,
} from "../../data";
export default function Portfolio() {

const [selected, setSelected] = useState("Featured");
const [data, setData] = useState()
  const list = [
    {
      id: "Featured",
      title: "Featured",
    },
    {
      id: "Web App",
      title: "Web App",
    },
    {
      id: "Mobile App",
      title: "Mobile App",
    },
    {
      id: "Desing",
      title: "Desing",
    },
    {
      id: "Content",
      title: "Content",
    },
  ];

useEffect (() => {
  switch(selected) {
    case "Featured" :
      setData(featuredPortfolio);
      break;
      case "Web":
        setData(webPortfolio);
        break;
      case "Mobile":
        setData(mobilePortfolio);
        break;
      case "Design":
        setData(designPortfolio);
        break;
      case "Content":
        setData(contentPortfolio);
        break;
      default:
        setData(featuredPortfolio);
  }

},[selected]);

  return (
    <div className="portfolio" id="portfolio">
      <h1>Portfolio</h1>
      <ul>
       {list.map((item,index) => (
        <PortfolioList
         title={item.title} 
         active={selected === item.id} 
         setSelected={setSelected} 
         id={item.id}
         key={index} />
       ))}
      </ul>
      <div className="container" >
        { data && data.map ((d) => (
        <div className="item" >
          <img src={d.img}
          alt=""  />
          <h3>{d.title}</h3>
        </div>
         ))}
      </div>
      </div>
  )
}
